/* ======================================================================== */
/* 5. buttonBordered */
/* ======================================================================== */
.button_bordered
	&.button_white
		border-color: #fff
		color: #fff
	&.button_black
		border-color: $black
		color: $black
