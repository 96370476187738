/* ======================================================================== */
/* 82. sliderImages */
/* ======================================================================== */
.slider-images__header
	margin-bottom: 1em	
.slider-images__footer
	margin-top: 1em
.slider-images_touched
	.slider__zoom-container
		transform: scale(1.1)

@media screen and (max-width: $md)
	.slider-images__header
		padding-left: 20px
		padding-right: 20px
	.slider-images__footer
		padding-left: 20px
		padding-right: 20px
