/* ======================================================================== */
/* 93. transitionCurtain */
/* ======================================================================== */
.transition-curtain
	position: fixed
	top: 0
	left: 0
	width: 100vw
	height: 100vh
	transform: translateY(100%)
	margin: auto
	z-index: 100
	overflow: hidden
