/* ======================================================================== */
/* 91. imageAlignment */
/* ======================================================================== */
.alignnone
	margin: 1em 0
.aligncenter, div.aligncenter
	display: block
	margin: 1.5em auto 1.5em auto
.alignright
	float: right
	margin: 0.5em 0 0.5em 1.5em
.alignleft
	float: left
	margin: 0.5em 1.5em 0.5em 0
a img
	&.alignright
		float: right
		margin: 5px 0 24px 24px
	&.alignnone
		margin: 5px 24px 24px 0
	&.alignleft
		float: left
		margin: 5px 24px 24px 0
	&.aligncenter
		display: block
		margin-left: auto
		margin-right: auto
.wp-block-image
	margin-top: 2em
	margin-bottom: 2em
	figcaption
		margin-top: 1em
		margin-bottom: 1em
.wp-caption
	max-width: 100%
	text-align: center
	&.alignnone, &.alignleft
		margin: 0.25em 1.5em 1.5em 0
	&.alignright
		margin: 0.25em 0 1.5em 1.5em
	img
		border: 0 none
		height: auto
		margin: 0
		max-width: 100%
		padding: 0
		width: auto
	p.wp-caption-text
		font-size: 11px
		line-height: 17px
		margin: 0
		padding: 0 0.25em 0.25em

.wp-caption-text
	margin-top: 0.5em

.screen-reader-text
	clip: rect(1px, 1px, 1px, 1px)
	position: absolute !important
	height: 1px
	width: 1px
	overflow: hidden
	&:focus
		background-color: $very-light-pink
		border-radius: 3px
		box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6)
		clip: auto !important
		color: #21759b
		display: block
		font-size: 14px
		font-size: 0.875rem
		font-weight: bold
		height: auto
		left: 5px
		line-height: normal
		padding: 15px 23px 14px
		text-decoration: none
		top: 5px
		width: auto
		z-index: 100000
.bypostauthor
	display: block
